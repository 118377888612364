<template>
  <div class="side-card">
    <h2
      class="side-card__title">
      {{ title }}
    </h2>
    <p
      class="side-card__description">
      {{ description }}
    </p>
    <slot name="button" />
  </div>
</template>

<script>
export default {
  name: 'SideCard',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.side-card {
  padding: 32px;
  background-color: $grey-100;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);

  &__title {
    font-size: 22px;
    margin: 0;
  }

  &__description {
    margin: 30px 0;
  }
}
</style>
